/*! -----------------------------------------------------------------------------------

    Template Name: Cuba Admin
    Template URI: http://admin.pixelstrap.com/cuba/theme
    Description: This is Admin theme
    Author: Pixelstrap
    Author URI: https://themeforest.net/user/pixelstrap

-----------------------------------------------------------------------------------
 */
// *** utils ***

@import './utils/variables';

:root {
  --theme-deafult: #3861aed6;
  --theme-secondary: #96c1ff;
  --theme-light: #a7c6ed4f;
  --light-background: rgba(242, 243, 247, 0.7);
  --body-font-color: #2f2f3b;
  --chart-border: #ecf3fa;
  --recent-chart-bg: #fcfcfd;
  --light-bg: #f6f7f9;
  --white: #fff;
  --light2: #f5f6f9;
  --sidebar-border: rgba(0, 0, 0, 0.1);
  --chart-text-color: rgba(82, 82, 108, 0.8);
  --recent-dashed-border: rgba(82, 82, 108, 0.3);
  --chart-dashed-border: rgba(82, 82, 108, 0.2);
  --chart-progress-light: rgba(82, 82, 108, 0.1);
  --recent-box-bg: #f2f4f7;
  --recent-border: rgba(97, 101, 122, 0.25);
  --course-light-btn: #f2f3f7;
  --course-bg: #f7f7f7;
  --balance-profie-bg: #e0dfef;
  --view-grid-bg: #ecf3fa80;
  --view-border-marker: #cfcdfc;

  --fc-button-bg-color: var(--theme-deafult);
  --fc-button-active-bg-color: var(--theme-deafult);
  --fc-button-active-border-color: var(--theme-deafult);
  --fc-button-border-color: var(--theme-deafult);
  --fc-button-hover-bg-color: var(--theme-deafult);
  --fc-button-hover-border-color: var(--theme-deafult);
}

body[class='dark-only'] {
  --light-background: rgba(31, 32, 40, 0.65);
  --recent-chart-bg: #262932;
  --light-bg: #1d1e26;
  --white: #262932;
  --sidebar-border: rgba(255, 255, 255, 0.1);
  --light2: #1d1e26;
  --chart-border: #374558;
  --recent-box-bg: #1d1e26;
  --recent-border: #374558;
  --body-font-color: rgba(255, 255, 255, 0.6);
  --course-light-btn: var(--light-bg);
  --view-grid-bg: var(--light-bg);
  --view-border-marker: #2c2c45d4;
}

/* ---------------------
	*** Icons Scss ***
-----------------------*/
@import 'vendors/icofont';
@import 'vendors/themify';

/* ---------------------
	*** Base ***
-----------------------*/

@import 'base/reset';
@import 'base/typography';

//  *** components ***
@import '../scss/pages/custom.scss';
@import 'components/according.scss';
@import 'components/alert.scss';
@import 'components/buttons.scss';
@import 'components/card.scss';
@import 'components/datatable.scss';
@import 'components/dropdown.scss';
@import 'components/typeahead-search.scss';
@import 'components/scrollbar';
@import 'components/tooltip.scss';
@import 'components/dot-areas';

//	*** pages ***
@import 'pages/chat.scss';
@import 'pages/kanban.scss';
@import 'pages/page.scss';

// *** layout ***

@import 'layout/navs.scss';
@import 'layout/search.scss';
@import 'layout/sidebar.scss';
