/* ---------------------
 *** Vendors Scss ***
-----------------------*/

/* Dragula */
// @import "~dragula/dist/dragula.css";

/* Dropzone */
@import '~dropzone/dist/min/dropzone.min.css';

/* Leaflet Map */
@import '~leaflet/dist/leaflet.css';

// overlay
@import '@angular/cdk/overlay-prebuilt.css';
@import '~@ng-select/ng-select/themes/default.theme.css';

/* ---------------------
	*** Theme Scss ***
-----------------------*/
@import '/node_modules/bootstrap/scss/bootstrap';
@import './assets/scss/vendors/themify.scss';
@import './assets/scss/style.scss';
