@import './../utils/variables';

.dot-container {
  display: flex;
}

.dot {
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dot-france {
  background-color: $france-color;
}

.dot-monaco {
  background-color: $monaco-color;
}

.dot-saintropez {
  background-color: $saintropez-color;
}

.dot:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}
