.tooltip {
  &.bs-tooltip-top {
    .tooltip-arrow {
      &:before {
        border-top-color: $theme-medium-color;
      }
    }
  }
  &.bs-tooltip-bottom {
    .tooltip-arrow {
      &:before {
        border-bottom-color: $theme-medium-color;
        border-top-color: transparent;
      }
    }
  }
  &.bs-tooltip-start {
    .tooltip-arrow {
      &:before {
        border-left-color: $theme-medium-color;
        border-top-color: transparent;
      }
    }
  }
  &.bs-tooltip-end {
    .tooltip-arrow {
      &:before {
        border-right-color: $theme-medium-color;
        border-top-color: transparent;
      }
    }
  }
  .tooltip-inner {
    background-color: $theme-medium-color;
    color: $primary-color;
  }
  .tooltip-arrow {
    &:before {
      border-top-color: $theme-medium-color;
    }
  }
}
