/**=====================
    78. Sidebar CSS Start
==========================**/
%sidebar-after {
  position: absolute;
  content: '';
  top: 48%;
  width: 8px;
  border-top: 1px solid rgba($theme-body-sub-title-color, 0.4);
}

%sidebar-active-dot {
  position: absolute;
  content: '';
  width: 5px;
  height: 5px;
  top: 50%;
  transform: translateY(-50%);
  left: 25px;
  border-radius: 100%;
  background: var(--theme-deafult);
  opacity: 0;
  visibility: hidden;

  [dir='rtl'] & {
    left: unset;
    right: 25px;
  }
}

%sidebar-before {
  position: absolute;
  content: '';
  width: 1px;
  height: 0;
  background: rgba($theme-body-sub-title-color, 0.4);
  top: -10px;
  left: 27px;
  transition: height 0.5s;

  [dir='rtl'] & {
    left: unset;
    right: 27px;
  }
}

%horizontal-after {
  position: absolute;
  content: '';
  top: 49%;
  width: 5px;
  height: 1px;
  border-radius: 100%;
  background-color: #59667a;
}

%for-animated-hover {
  box-shadow: $main-header-shadow;
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  left: 0;
}

%for-animated-hover-box {
  opacity: 1;
  transform: translateY(0px);
  visibility: visible;
  border-radius: 5px;
  overflow: hidden;

  &:before {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid $white;
    content: '';
    top: -7px;
    position: absolute;
    left: 10px;
    z-index: 2;
  }

  &:after {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid $light-background;
    content: '';
    top: -7px;
    position: absolute;
    left: 10px;
    z-index: 1;
  }
}

.form-control-plaintext {
  color: $theme-body-font-color;
}

.page-wrapper {
  position: relative;

  .page-header {
    max-width: 100vw;
    position: $main-header-position;
    top: $main-header-top;
    z-index: $main-header-z-index;
    transition: $sidebar-transition;
    background-color: $white;
    box-shadow: $main-header-shadow-1;

    .header-wrapper {
      // width: calc(100% - 12rem);
      align-items: center;
      //margin-left: 12rem !important; //sidebar width

      padding: $header-wrapper-padding;
      position: relative;
      background-color: rgba(77, 98, 110, 0.3);

      @media (max-width: 1399px) {
        padding: 22px 20px;
      }

      .search-full {
        background-color: $white;
        position: absolute;
        right: 0;
        z-index: 3;
        height: 0;
        width: 0;
        transform: scale(0);
        transition: all 0.3s ease;
        top: 0;

        &.open {
          height: 100%;
          width: 100%;
          animation: zoomIn 0.5s ease-in-out;
          transform: scale(1);
          transition: all 0.3s ease;
        }

        input {
          line-height: 65px;
          padding-left: 60px;
          width: 100% !important;

          &:focus {
            outline: none !important;
          }
        }

        .form-group {
          .close-search {
            position: absolute;
            font-size: 15px;
            right: 30px;
            top: 30px;
            color: $dark-gray;
            cursor: pointer;
          }

          &:before {
            position: absolute;
            left: 30px;
            top: 27px;
            content: '\f002';
            font-size: 16px;
            color: $dark-gray;
            font-family: FontAwesome;
          }
        }
      }

      .nav-right {
        justify-content: flex-end;

        ul {
          li {
            svg {
              vertical-align: $header-wrapper-nav-icon-align;
              width: $header-wrapper-nav-icon-size;
              height: $header-wrapper-nav-icon-size;
              stroke: rgba($theme-body-font-color, 0.9);
              fill: none;

              path {
                color: $header-wrapper-nav-icon-color;
              }
            }

            .mode {
              &.active {
                svg {
                  fill: $white;
                }
              }
            }
          }
        }

        &.right-header {
          ul {
            li {
              .mode {
                cursor: pointer;
                text-align: center;

                i {
                  font-size: 18px;
                  font-weight: 600;
                  transition: all 0.3s ease;

                  &.fa-lightbulb-o {
                    font-weight: 500;
                    font-size: 22px;
                    transition: all 0.3s ease;
                    animation: zoomIn 300ms ease-in-out;
                  }
                }
              }

              .header-search {
                cursor: pointer;
              }

              .profile-media {
                .media-body {
                  margin-left: 15px;

                  span {
                    font-weight: 500;
                  }

                  p {
                    font-size: 12px;
                    line-height: 1;
                    color: rgba($theme-body-font-color, 0.7);
                  }
                }
              }
            }
          }
        }

        > ul {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          > li {
            padding: 6px 10px;
            display: inline-block;
            position: relative;

            span {
              ul {
                left: inherit;
                right: -10px;
                width: 130px;

                &:before,
                &:after {
                  left: inherit;
                  right: 10px;
                }

                li {
                  display: block;

                  a {
                    font-size: 14px;
                    color: $dark-color;

                    i {
                      margin-left: 10px;
                      font-size: 13px;
                      color: $dark-color;
                    }
                  }
                }
              }
            }
          }

          .flag-icon {
            font-size: 16px;
          }
        }

        .bookmark-flip {
          width: unset !important;
          box-shadow: unset !important;
          background-color: transparent !important;
          overflow: initial;

          &::after,
          &::before {
            display: none;
          }

          .bookmark-dropdown {
            li {
              padding: 0px;

              .bookmark-content {
                text-align: center;

                .bookmark-icon {
                  width: 52px;
                  height: 52px;
                  background-color: #f9f9f9;
                  border-radius: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin: 0 auto;
                }

                span {
                  font-family: $font-roboto, $font-serif;
                  font-size: 12px;
                  font-weight: 500;
                  letter-spacing: 0.5px;
                  color: #222;
                }
              }
            }
          }
        }

        .flip-card {
          width: 300px;
          height: 300px;
          background-color: transparent;

          .flip-card-inner {
            position: relative;
            height: 100%;
            text-align: center;
            transition: transform 0.6s;
            transform-style: preserve-3d;

            .front,
            .back {
              position: absolute;
              backface-visibility: hidden;
              border-radius: 10px;
              overflow: hidden;
              box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
              width: 300px;
              height: 300px;

              button {
                background-color: $transparent-color;
                color: $primary-color;
                border: none;

                &:focus {
                  outline: $transparent-color;
                }
              }
            }

            .front {
              background-color: $white;
              transition: 0.3s;

              svg {
                stroke: $dark-color;
                transition: all 0.3s ease;

                &:hover {
                  stroke: var(--theme-deafult);
                  transition: all 0.3s ease;
                }
              }
            }

            .back {
              background-color: $white;
              transform: rotateY(180deg);

              .Typeahead-menu {
                max-height: 150px;
              }

              li {
                &:first-child {
                  margin-top: 20px;
                  height: 150px !important;
                }

                &:last-child {
                  position: absolute;
                  bottom: -100px;
                  left: 50%;
                  transform: translateX(-50%);
                }
              }

              button {
                width: 100%;
              }

              .flip-back-content {
                input {
                  border-radius: 25px;
                  padding: 6px 12px;
                  width: 100%;
                  border: 1px solid #ddd;

                  &:focus {
                    outline: none;
                  }
                }
              }
            }

            &.flipped {
              transform: rotateY(180deg);

              .front {
                opacity: 0;
              }
            }
          }
        }

        .onhover-show-div {
          width: 330px;
          top: 50px;
          right: 0;
          left: unset;

          .dropdown-title {
            padding: 20px;
            text-align: center;
            border-bottom: 1px solid rgba($dropdown-border-color, 0.3);
          }

          ul {
            padding: 15px;
          }

          li {
            padding: 15px;

            p {
              font-size: 14px;
              letter-spacing: 0.3px;
              margin-bottom: 0;
            }

            a {
              text-decoration: underline;
              text-transform: uppercase;
              letter-spacing: 0.3px;
              font-family: $font-roboto, $font-serif;
            }

            & + li {
              margin-top: 8px;
            }

            &:last-child {
              padding-bottom: 15px;
            }

            .media {
              position: relative;

              img {
                width: 40px;
                position: relative;
              }

              .status-circle {
                left: 0;
              }

              .media-body {
                > span {
                  letter-spacing: 0.8px;
                  padding-right: 10px;
                  display: inline-block;
                }

                p {
                  margin-bottom: 8px;
                }
              }
            }
          }

          &:before,
          &:after {
            right: 10px !important;
            left: unset !important;
          }
        }

        .notification-box {
          position: relative;
        }

        .cart-box {
          position: relative;
        }

        .cart-dropdown {
          ul {
            padding: 0 0 15px;
          }

          li {
            margin: 0 15px;
            padding: 10px 0;

            .media {
              align-items: center;

              .media-body {
                margin-right: 20px;

                [dir='rtl'] & {
                  margin-right: unset;
                  margin-left: 20px;
                }
              }
            }

            & + li {
              margin-top: 0;
              border-top: 1px solid rgba($dropdown-border-color, 0.1);
            }

            &:last-child {
              padding: 20px 15px;
              border-top: none;
            }
          }

          p {
            line-height: 1.3;
            margin-top: 3px;
            opacity: 0.6;
          }

          .close-circle {
            position: absolute;
            right: 0;
            top: 0;

            a {
              width: 18px;
              height: 18px;
              border-radius: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            svg {
              width: 14px;
              stroke: $white;
            }
          }
        }

        .notification-box {
          svg {
            animation: swing 1.5s ease infinite;
          }
        }
      }
    }
  }

  .page-body-wrapper {
    background-color: $main-body-color;

    .page-body {
      min-height: calc(100vh);
      transition: 0.5s;
    }

    .page-title {
      padding: $page-title-padding;
      background-color: rgb(218 218 229 / 70%);
    }

    div.sidebar-wrapper {
      .sidebar-main {
        .sidebar-links {
          .simplebar-content-wrapper {
            > li {
              a {
                svg {
                  &.stroke-icon {
                    stroke: $dark-color;
                  }

                  &.fill-icon {
                    fill: $sidebar-fill-icon-color;
                  }
                }

                &.active {
                  svg {
                    transition: all 0.3s ease;

                    &.stroke-icon {
                      stroke: var(--theme-deafult);
                    }

                    &.fill-icon {
                      fill: var(--theme-deafult);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .sidebar-wrapper {
    &[sidebar-layout='stroke-svg'] {
      .stroke-icon {
        display: inline-block;
      }

      .fill-icon {
        display: none;
      }
    }

    &[sidebar-layout='fill-svg'] {
      .stroke-icon {
        display: none;
      }

      .fill-icon {
        display: inline-block;
      }
    }
  }

  &.horizontal-wrapper {
    [data-simplebar] {
      position: relative;
    }

    .footer {
      margin-left: 0;
    }

    .page-body-wrapper {
      .sidebar-wrapper {
        height: 54px;
        line-height: inherit;
        top: 79px;
        background: $white;
        box-shadow: 0px 9px 24px rgba($semi-dark, 0.05);
        overflow-x: hidden;
        z-index: 1;
        overflow-y: visible;
        position: fixed;
        width: 100vw;
        border-top: 1px solid $light-semi-gray;
        z-index: 3;

        li {
          display: inline-block;
        }

        .sidebar-main {
          text-align: left;
          position: relative;
        }

        &.overlay-white {
          background-color: rgba(255, 255, 255, 0.08);
        }
      }

      .toggle-nav {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;

        i {
          font-size: 24px;
        }
      }

      .page-body {
        margin-top: 135px;
      }
    }

    .page-header {
      width: 100%;
      box-shadow: none;

      .header-wrapper {
        .form-control-plaintext {
          width: auto !important;
        }
      }
    }
  }

  // left sidebar css
  &.compact-wrapper {
    .page-header {
      width: 100%;

      .header-wrapper {
        .toggle-sidebar {
          display: none;
        }
      }
    }

    .page-body-wrapper {
      div {
        &.sidebar-wrapper {
          position: fixed;
          top: 0;
          z-index: 1000;
          height: 100%;
          line-height: inherit;
          background: $white;
          width: $sidebar-compact-width;
          text-align: left;
          transition: 0.3s;
          box-shadow: 0 0 21px 0 rgba($semi-dark, 0.1);

          .logo-wrapper {
            padding: 22px 30px;
            box-shadow: -9px 0 20px rgba($semi-dark, 0.1);
          }

          &.close_icon {
            width: auto;
            transition: all 0.3s ease;

            //hide logo when collapsed

            .logo-wrapper {
              display: none;
            }

            &:hover {
              width: $sidebar-compact-width;
              transition: all 0.3s ease;

              .sidebar-main {
                .sidebar-links {
                  .simplebar-content-wrapper {
                    > li {
                      max-width: unset;
                      margin: initial;
                      transition: all 0.3s ease;

                      a {
                        text-align: left;

                        [dir='rtl'] & {
                          text-align: right;
                        }
                      }

                      .sidebar-submenu {
                        a {
                          display: block;
                        }
                      }

                      > a {
                        padding: 12px 15px;
                        transition: all 0.3s ease;

                        span {
                          display: inline-block;
                        }
                      }
                    }
                  }
                }
              }

              .logo-wrapper {
                display: block;
              }
            }

            ~ .page-body,
            ~ .breadcrumb-header,
            ~ footer {
              margin-left: 3.5rem;
              transition: 0.5s;
            }

            ~ .footer-fix {
              width: calc(100% - 90px);
            }

            .sidebar-main {
              .sidebar-links {
                .simplebar-content-wrapper {
                  > li {
                    .sidebar-link {
                      &::before {
                        right: 20px;
                        left: unset;
                      }
                    }

                    a {
                      span {
                        display: none;
                      }
                    }
                  }
                }

                .sidebar-list {
                  .sidebar-title {
                    &.active {
                      ~ .sidebar-submenu {
                        display: block;
                      }
                    }
                  }
                }
              }
            }

            .according-menu {
              display: none;
            }
          }

          .sidebar-main {
            .left-arrow,
            .right-arrow {
              display: none;
            }

            .sidebar-links {
              height: $sidebar-height;
              z-index: 99;
              transition: color 1s ease;
              overflow: auto;
              color: rgba(0, 0, 0, 0);
              margin-top: 20px;
              margin-bottom: 30px;

              li {
                a {
                  font-weight: 500;

                  svg {
                    width: 18px;
                    height: 18px;
                    margin-right: 10px;
                    vertical-align: -2px;
                    float: none;
                    transition: all 0.3s ease;
                  }

                  span {
                    letter-spacing: 1px;
                    text-transform: capitalize;
                    color: rgba($theme-body-sub-title-color, 0.8);
                  }

                  &.link-nav {
                    .according-menu {
                      display: none;
                    }
                  }
                }

                &:last-child {
                  border-bottom: none;
                }
              }

              &.opennav {
                left: 0;
              }

              .back-btn {
                display: block;
                width: 100%;
                padding: 0;
              }

              .simplebar-content-wrapper {
                > li {
                  display: block;
                  width: 100%;
                  position: relative;

                  &.sidebar-list {
                    &:hover {
                      > a {
                        &:hover {
                          background-color: rgba($primary-color, 0.07);
                          transition: all 0.3s ease;
                        }
                      }
                    }
                  }

                  &:hover {
                    .sidebar-link:not(.active) {
                      &:hover {
                        svg {
                          fill: rgba($primary-color, 0.1%);
                          stroke: var(--theme-deafult);
                          transition: all 0.3s ease;

                          &.fill-icon {
                            fill: var(--theme-deafult);
                            stroke: none;
                          }
                        }

                        span {
                          color: var(--theme-deafult);
                          transition: all 0.3s ease;
                        }

                        .according-menu {
                          i {
                            color: var(--theme-deafult);
                            transition: all 0.3s ease;
                          }
                        }
                      }
                    }
                  }

                  a {
                    padding: 12px 20px;
                    position: relative;
                    color: #222222;
                    transition: all 0.3s ease;

                    .sub-arrow {
                      right: 20px;
                      position: absolute;
                      top: 10px;

                      i {
                        display: none;
                      }

                      &:before {
                        display: none;
                      }
                    }
                  }

                  &:first-child {
                    display: none !important;
                  }

                  .sidebar-submenu {
                    width: 100%;
                    padding: 0;
                    position: relative !important;

                    &::before {
                      @extend %sidebar-before;
                    }

                    > li {
                      a {
                        padding-left: 48px !important;
                        font-weight: 400;
                      }
                    }

                    .submenu-title {
                      .according-menu {
                        top: 7px;
                      }
                    }

                    &.opensubmenu {
                      display: block;
                    }

                    li {
                      &:hover {
                        > a {
                          color: var(--theme-deafult);
                          transition: all 0.3s ease;
                        }
                      }

                      .nav-sub-childmenu {
                        display: none;
                        position: relative !important;
                        right: 0;
                        width: 100%;
                        padding: 0;

                        &.opensubchild {
                          display: block;
                        }

                        li {
                          a {
                            padding: 6px 45px;
                            padding-left: 56px !important;

                            &:after {
                              display: none;
                            }
                          }
                        }
                      }

                      a {
                        padding: 7px 25px;
                        font-size: 14px;
                        color: rgba($theme-body-font-color, 0.6);
                        display: block;
                        position: relative;
                        letter-spacing: 0.06em;
                        font-weight: 500;
                        transition: all 0.3s ease;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        &::before {
                          @extend %sidebar-active-dot;
                        }

                        &.active {
                          color: var(--theme-deafult);

                          &::before {
                            opacity: 1;
                            visibility: visible;
                          }
                        }

                        &:after {
                          left: 27px;
                          @extend %sidebar-after;
                        }

                        &:hover {
                          margin-left: 0;
                        }
                      }
                    }
                  }

                  .sidebar-link {
                    transition: all 0.5s ease;
                    display: block;

                    &::after {
                      position: absolute;
                      content: '';
                      width: 4px;
                      height: 35px;
                      right: 0;
                      top: 50%;
                      transform: translateY(-50%);
                      background-color: $primary-color;
                      border-radius: 5px 0px 0px 5px;
                      opacity: 0;
                      visibility: hidden;

                      [dir='rtl'] & {
                        right: unset;
                        left: 0;
                      }
                    }

                    &.active {
                      transition: all 0.5s ease;
                      position: relative;
                      margin-bottom: 10px;
                      background-color: lighten($primary-color, 27%);

                      &::after {
                        opacity: 1;
                        visibility: visible;
                      }

                      ~ .sidebar-submenu {
                        &::before {
                          height: calc(100% - 8px);
                          transition: height 0.5s;
                        }
                      }

                      .according-menu {
                        i {
                          color: var(--theme-deafult);
                        }
                      }

                      span {
                        color: var(--theme-deafult);
                        transition: all 0.3s ease;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .according-menu {
        position: absolute;
        right: 20px;
        top: 13px;
        display: block;

        i {
          vertical-align: middle;
          color: rgba($theme-body-sub-title-color, 0.8);
          font-weight: 500;
        }
      }

      .page-body {
        // min-height: calc(100vh - 80px);
        margin-left: $sidebar-compact-width;
        // animation: pagebody 1000ms ease-in-out;
      }
    }

    &.dark-sidebar,
    &.color-sidebar {
      .toggle-sidebar {
        svg {
          stroke: var(--theme-deafult);
        }
      }

      .page-body-wrapper {
        div.sidebar-wrapper {
          .sidebar-main {
            .sidebar-links {
              .simplebar-content-wrapper {
                > li {
                  a {
                    svg {
                      &.stroke-icon {
                        stroke: $dark-all-font-color;
                      }

                      &.fill-icon {
                        fill: $dark-all-font-color;
                      }
                    }

                    &.active {
                      svg {
                        &.stroke-icon {
                          stroke: var(--theme-deafult);
                        }

                        &.fill-icon {
                          fill: var(--theme-deafult);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      div.sidebar-wrapper {
        background-color: $dark-card-background;

        .sidebar-main {
          .sidebar-links {
            li {
              a {
                span {
                  color: $dark-all-font-color;
                }

                svg {
                  &.stroke-icon {
                    stroke: $dark-all-font-color;
                  }

                  &.fill-icon {
                    fill: $dark-all-font-color;
                  }
                }
              }
            }

            .simplebar-content-wrapper {
              > li {
                .sidebar-link {
                  &.active {
                    background-color: rgba($primary-color, 0.2);

                    svg {
                      color: var(--theme-deafult);
                      stroke: var(--theme-deafult);
                    }
                  }
                }

                .sidebar-submenu {
                  li {
                    a {
                      color: $dark-all-font-color;
                    }
                  }
                }
              }
            }

            .sidebar-list {
              ul.sidebar-submenu {
                li {
                  a {
                    span {
                      color: $dark-all-font-color;
                    }

                    &.active {
                      color: var(--theme-deafult);
                    }
                  }
                }
              }
            }
          }
        }
      }

      .according-menu {
        i {
          color: $dark-all-font-color;
        }
      }
    }

    &.color-sidebar {
      div.sidebar-wrapper {
        background-color: #2f3c4e;
      }
    }
  }

  // compact icon css
  &.compact-sidebar {
    ~ .bg-overlay1 {
      transition: 0.8s;

      &.active {
        z-index: 8;
        height: 100vh;
        width: 100vw;
        background-color: rgba($black, 0.2);
        position: fixed;
        top: 0;
      }
    }

    .toggle-sidebar {
      position: relative;
      right: unset;
      top: unset;
      margin-right: 15px;
    }

    .logo-wrapper {
      display: none;
    }

    .page-header {
      margin-left: 150px;
      width: calc(100% - 150px);

      .header-wrapper {
        .logo-wrapper {
          display: none;
        }

        .left-header {
          width: 30%;

          @media (max-width: 1578px) and (min-width: 1274px) {
            width: 38%;
          }

          @media (max-width: 1273px) and (min-width: 1200px) {
            width: 45%;
          }

          @media (max-width: 991px) and (min-width: 768px) {
            width: 36%;
          }

          @media (max-width: 767px) and (min-width: 756px) {
            width: 28%;
          }

          h6 {
            @media (max-width: 1199px) {
              width: 132px;
            }

            @media (max-width: 767px) {
              width: 83px;
            }
          }
        }
      }

      &.close_icon {
        margin-left: 0;
        width: calc(100% - 0px);
      }
    }

    .page-body-wrapper {
      .page-body {
        margin-top: 80px;
        margin-left: 150px;
      }

      .footer {
        margin-left: 150px;
      }

      div.sidebar-wrapper {
        position: fixed;
        top: 0;
        z-index: 9;

        > div {
          height: auto;
          line-height: inherit;
          background: $white;
          width: 150px;
          text-align: left;
          transition: 0.3s;
          box-shadow: 0 0 21px 0 rgba(89, 102, 122, 0.1);
        }

        .sidebar-main {
          .left-arrow,
          .right-arrow {
            display: none;
          }

          .sidebar-links {
            margin: 30px 0;
            height: calc(100vh - 113px);
            overflow: auto;

            li {
              a {
                color: $dark-color;

                span {
                  text-transform: capitalize;
                  color: $dark-color;
                  font-weight: 500;
                  letter-spacing: 1px;
                  font-size: 14px;
                }

                &.active {
                  background: rgba($primary-color, 0.1);
                  color: $primary-color;
                  border-radius: 20px;
                  margin: 0 10px;

                  span {
                    color: $primary-color;
                  }

                  ~ .sidebar-submenu,
                  ~ .mega-menu-container {
                    animation: fadeInLeft 300ms ease-in-out;
                  }
                }
              }

              .sidebar-title {
                display: block;
                padding: 20px 0;
                border-bottom: 1px solid #f3f3f3;
              }

              .sidebar-submenu,
              .mega-menu-container {
                position: fixed;
                top: 0px;
                height: calc(100vh - 0px);
                overflow: auto;
                left: 150px;
                width: 230px;
                background-color: $white;
                box-shadow: 0 16px 15px 15px rgba(126, 55, 216, 0.03);
                transition: all 0.5s ease-in-out;
                display: none;
                padding-top: 20px;
                z-index: -1;

                li {
                  a {
                    padding: 15px 15px 15px 40px;
                    display: block;
                    position: relative;
                    border-bottom: 1px solid #f1f1f1;

                    .sub-arrow {
                      display: none;
                    }

                    .according-menu {
                      position: absolute;
                      right: 20px;
                      top: 16px;
                    }

                    &.active {
                      background-color: rgba($primary-color, 0.1);
                      color: $primary-color;
                      transition: all 0.3s ease;
                    }
                  }

                  .submenu-content {
                    li {
                      a {
                        &.active {
                          background-color: $transparent-color;
                        }
                      }
                    }
                  }
                }

                > li {
                  > a {
                    &::after {
                      position: absolute;
                      content: '';
                      left: 20px;
                      top: 48%;
                      width: 8px;
                      border-top: 2px solid
                        rgba($theme-body-sub-title-color, 0.4);
                    }
                  }
                }

                .mega-box {
                  flex: 0 0 100%;
                  max-width: 100%;
                  padding: 0;

                  .link-section {
                    text-align: left;

                    .submenu-title {
                      h5 {
                        position: relative;
                        font-size: 14px;
                        font-weight: 400;
                        padding: 15px 15px 15px 40px;
                        letter-spacing: 1px;
                        margin-bottom: 0;

                        &::after {
                          position: absolute;
                          content: '';
                          left: 20px;
                          top: 48%;
                          width: 6px;
                          border-top: 2px solid #595c60;
                        }
                      }
                    }
                  }
                }
              }
            }

            .sidebar-list {
              position: relative;

              .sidebar-link {
                text-align: center;

                svg {
                  margin: 0 auto 6px;
                  width: 24px;
                  height: 24px;
                }

                > span {
                  display: block;
                  font-weight: 500;
                  letter-spacing: 1px;
                  font-size: 14px;
                  word-break: break-word;
                }

                .according-menu {
                  display: none;
                }
              }
            }
          }
        }

        &.close_icon {
          transform: translateX(-150px);

          ~ .page-body,
          ~ .footer {
            margin-left: 0px;
          }
        }
      }
    }
  }

  // material css
  &.material-type {
    position: relative;

    &::before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      width: 100%;
      height: 300px;
      background-image: $gradient-bg-primary;
    }

    .card {
      box-shadow: 0 0 20px rgba($black, 0.05);
    }

    .page-header {
      position: relative;
      max-width: 1560px;
      margin: 50px auto 0;
      border-radius: 15px 15px 0 0;

      .header-wrapper {
        .left-header {
          width: 31%;

          @media (max-width: 1600px) {
            width: 33%;
          }

          @media (max-width: 1366px) {
            width: 38%;
          }

          @media (max-width: 1199px) {
            width: 30%;
          }
        }

        .nav-right {
          @media (max-width: 1600px) {
            width: 40%;
          }

          @media (max-width: 1199px) {
            width: 45%;
          }
        }
      }
    }

    .page-body-wrapper {
      background-blend-mode: overlay;
      background-color: rgba($white, 0.5);

      .sidebar-wrapper {
        position: relative;
        max-width: 1560px;
        margin: 0 auto;
        top: unset;
        height: 56px;
      }

      .page-body {
        margin: 0 auto;
        max-width: 1560px;
        background-color: $body-color;
        box-shadow: 0 0 20px 7px rgba(8, 21, 66, 0.05);
        border-radius: 0 0 15px 15px;
      }
    }

    .footer {
      margin-top: 40px;
    }
  }
}

// sidebar filter
.md-sidebar {
  position: relative;

  .md-sidebar-toggle {
    display: none;
    width: fit-content;
    text-transform: capitalize;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1199px) {
  .page-wrapper {
    .page-header {
      .header-wrapper {
        .nav-right {
          > ul {
            > li {
              padding: 6px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  // material icon css
  .page-wrapper {
    &.material-icon {
      &::before {
        height: 240px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .sidebar-wrapper {
    .menu {
      .brand-logo {
        img {
          width: 120px;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .toggle-nav {
    i {
      font-size: 20px;
    }
  }
}

/**=====================
    78. Sidebar CSS Ends
==========================**/
